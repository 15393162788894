<template>
	<div
		class="modal"
		:class="[(activeModal === 'pay' || activeModal === 'halloween') && 'is-active']"
	>
		<div class="modal__wrapper" @click="hideModal">
			<div
				class="modal__body"
				@click.stop
				v-scroll-lock="activeModal === 'pay' || activeModal === 'halloween'"
			>
				<div class="modal__close" @click="hideModal">
					<SvgIcon name="close" />
				</div>

				<div class="modal__title">Введите email, чтобы мы могли прислать чек.</div>

				<V-Form
					ref="form"
					v-slot="{ meta: { touched, valid } }"
					as="form"
					class="form"
					@submit="handleSubmit"
				>
					<V-Field
						v-model="email"
						name="email"
						type="email"
						v-slot="{ errorMessage, field }"
						rules="email|required"
					>
						<UiInput
							placeholder="Ваш email"
							icon="email"
							class="ui-group"
							:clearable="true"
							:error="errorMessage ? true : false"
							v-bind="field"
						/>
					</V-Field>

					<div class="form__price" v-if="modalParams.quantity">
						<template v-if="isPremium">
							<span>{{ this.modalParams.product_name }}</span>
						</template>
						<template v-else>
							<div class="form__price-product" v-if="isHalloweenProduct">
								<p>
									<img
										src="@/assets/img/coins_icon.png"
										srcset="@/assets/img/coins_icon@2x.png 2x"
										alt="coins"
									/>
									<span>Х&nbsp;&nbsp;{{ modalParams.quantity }}</span>
								</p>
								<!-- <p>
									<img
										src="@/assets/img/ghost.png"
										srcset="@/assets/img/ghost@2x.png 2x"
										alt="coin"
									/>
									<span>Х&nbsp;&nbsp;{{ modalParams.quantityGhost }}</span>
								</p> -->
							</div>
							<div class="form__price-product" v-else-if="isEmba">
								<p>
									<img
										class="__w-42"
										src="@/assets/img/disketa_icon.png"
										srcset="@/assets/img/disketa_icon@2x.png 2x"
										alt="coins"
									/>
									<span>Х&nbsp;&nbsp;{{ modalParams.quantityDisk }}</span>
								</p>
							</div>
							<div class="form__price-product" v-else-if="isChina">
								<p>
									<img
										class="__w-42"
										src="@/assets/img/сhinese_coin.png"
										srcset="@/assets/img/сhinese_coin@2x.png 2x"
										alt="coins"
									/>
									<span>Х&nbsp;&nbsp;{{ modalParams.quantityChina }}</span>
								</p>
							</div>
							<div class="form__price-product" v-else-if="isXmas || isNYProduct">
								<p>
									<img
										src="@/assets/img/bowl_icon.png"
										srcset="@/assets/img/bowl_icon@2x.png 2x"
										alt="coins"
									/>
									<span>Х&nbsp;&nbsp;{{ modalParams.quantityBowls }}</span>
								</p>
								<!-- <p>
									<img
										src="@/assets/img/cookie_icon.png"
										srcset="@/assets/img/cookie_icon@2x.png 2x"
										alt="coin"
									/>
									<span>Х&nbsp;&nbsp;{{ modalParams.quantityCookies }}</span>
								</p> -->

								<p v-if="modalParams.quantityElf">
									<img
										src="@/assets/img/elf_icon.png"
										srcset="@/assets/img/elf_icon@2x.png 2x"
										alt="coin"
									/>
									<span>Х&nbsp;&nbsp;{{ modalParams.quantityElf }}</span>
								</p>

								<p v-if="modalParams.quantityIce">
									<img
										src="@/assets/img/ice_icon.png"
										srcset="@/assets/img/ice_icon@2x.png 2x"
										alt="coin"
									/>
									<span>Х&nbsp;&nbsp;{{ modalParams.quantityIce }}</span>
								</p>

								<p v-if="modalParams.quantityGluehwein">
									<img
										src="@/assets/img/gluehwein_icon.png"
										srcset="@/assets/img/gluehwein_icon@2x.png 2x"
										alt="coin"
									/>
									<span>Х&nbsp;&nbsp;{{ modalParams.quantityGluehwein }}</span>
								</p>

								<p v-if="modalParams.quantityCola">
									<img
										src="@/assets/img/cola_icon.png"
										srcset="@/assets/img/cola_icon@2x.png 2x"
										alt="coin"
									/>
									<span>Х&nbsp;&nbsp;{{ modalParams.quantityCola }}</span>
								</p>
							</div>
							<template v-else>
								<img
									src="/static/img/shop/cap@1x.png"
									srcset="/static/img/shop/cap@2x.png 2x"
									alt="coin"
								/>
								<span>Х&nbsp;&nbsp;{{ quantityWithBonus }}</span>
							</template>
						</template>
					</div>

					<div class="form__cta" v-if="modalParams.price">
						<UiButton theme="accent" type="submit" :disabled="!valid">
							Купить за {{ modalParams.price.toLocaleString() }} ₽
						</UiButton>
					</div>
				</V-Form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex"
import {
	easterProduct,
	halloweenProduct,
	halloweenProductPremium,
	isNewYearProduct,
	startProduct,
	lightButtons,
	priceRight,
	xMasProduct,
	eMbaProduct,
	chinaProduct,
} from "../../api/helpers"

export default {
	data() {
		return {
			activeCheckboxes: [],
			email: "",
			error: null,
		}
	},
	computed: {
		isPremium() {
			return (
				this.modalParams.quantity === 1 ||
				halloweenProductPremium(this.modalParams.product_id) ||
				easterProduct(this.modalParams.product_id)
			)
		},
		isHalloweenProduct() {
			return halloweenProduct(this.modalParams.product_id)
		},
		isXmas() {
			return xMasProduct(this.modalParams.product_id)
		},
		isEmba() {
			return eMbaProduct(this.modalParams.product_id)
		},
		isChina() {
			return chinaProduct(this.modalParams.product_id)
		},
		start() {
			return startProduct(this.modalParams.product_id)
		},
		isLightButtons() {
			return lightButtons(this.modalParams.product_id)
		},
		isPriceRight() {
			return priceRight(this.modalParams.product_id)
		},
		isEasterProduct() {
			return easterProduct(this.modalParams.product_id)
		},
		isNYProduct() {
			return isNewYearProduct(this.modalParams.product_id)
		},
		quantityBowls() {
			return this.modalParams.itemQuantityBowls
		},
		quantityCookies() {
			return this.modalParams.itemQuantityCookies
		},
		quantityElf() {
			return this.modalParams.itemQuantityElf
		},
		quantityIce() {
			return this.modalParams.itemQuantityIce
		},
		quantityCola() {
			return this.modalParams.itemQuantityCola
		},
		quantityGluehwein() {
			return this.modalParams.itemQuantityGluehwein
		},
		quantityWithBonus() {
			let bonus = 0
			if (this.modalParams.bonus) {
				bonus = +this.modalParams.bonus
			}

			return this.modalParams.quantity + bonus
		},
		...mapState("user", ["user"]),
		...mapState("ui", ["activeModal", "modalParams"]),
	},
	methods: {
		async handleSubmit() {
			const isValid = await this.$refs.form.validate()
			if (!isValid) {
				return
			}

			const {
				email,
				user: { id },
				user: { platform },
				user: { apps_flyer_id },
				user: { apk_type },
				modalParams: { price, quantity, product_id },
			} = this

			await this.initPayment({
				data: {
					user_id: id,
					product_id: product_id,
					email: email,
					platform: platform,
					apps_flyer_id: apps_flyer_id,
					apk_type: apk_type,
				},
			})
				.then((res) => {
					this.hideModal()
					// this.$router.push("/")
					// window.open(res)
					location.href = res
				})
				.catch((err) => {
					this.setModal({
						name: "error",
					})
				})
		},
		...mapMutations("ui", ["setModal", "hideModal"]),
		...mapActions("shop", ["initPayment"]),
	},
}
</script>

<style lang="scss" scoped>
.modal {
	position: fixed;
	z-index: 99;
	display: flex;
	flex-direction: column;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;

	&__wrapper {
		flex: 1 0 auto;
		height: 100%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0);
		backface-visibility: hidden;
		will-change: background;
		transition: background 0.25s $ease;
	}

	&__body {
		position: relative;
		background-image: url("@/assets/img/searchBox@1x.png");
		background-image: -webkit-image-set(
			url("@/assets/img/searchBox@1x.png") 1x,
			url("@/assets/img/searchBox@2x.png") 2x
		);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center top;
		width: 100%;
		max-width: 320px;
		min-height: 250px;
		padding: 30px 40px 24px;
		transform: translate3d(0%, 40px, 0);
		opacity: 0;
		overflow-y: auto;
		will-change: transform;
		backface-visibility: hidden;
		transition: transform 0.25s $ease, opacity 0.25s $ease;
	}

	&__close {
		position: absolute;
		z-index: 2;
		top: 5px;
		right: 25px;
		font-size: 0;
		padding: 10px;
		cursor: pointer;
		color: $colorPrimary;
		transition: color 0.25s $ease;

		.svg-icon {
			font-size: 15px;
		}

		&:hover {
			color: $colorRed;
		}
	}

	&__title {
		text-align: center;
		font-family: $russoFont;
		font-size: 13px;
		line-height: 1.25;
	}

	&.is-active {
		pointer-events: all;

		.modal__wrapper {
			background: rgba(#333333, 0.67);
		}

		.modal__body {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}
}

.form {
	margin-top: 12px;
	text-align: center;
	max-width: 225px;
	margin-left: auto;
	margin-right: auto;

	&__cta {
		margin-top: 8px;
	}

	&__price {
		margin-top: 10px;
		display: inline-flex;
		align-items: center;
		justify-content: center;

		img {
			flex: 0 0 auto;
			margin-right: 12px;
		}

		span {
			font-family: $russoFont;
			font-size: 25px;
			color: $colorGlow;
		}

		&-product {
			p {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0;

				img {
					width: 32px;
					height: 32px;

					&.__w-42 {
						width: 42px;
					}
				}

				+ p {
					margin-top: 5px;
				}
			}
		}
	}
}

@include rmin($sm) {
	.modal {
		&__body {
			max-width: 768px;
			margin-left: 0;
			margin-right: -15px;
			background-image: url("@/assets/img/searchBoxDesktop.png");
			background-image: -webkit-image-set(
				url("@/assets/img/searchBoxDesktop.png") 1x,
				url("@/assets/img/searchBoxDesktop@2x.png") 2x
			);
			min-height: 390px;
			padding: 60px 40px 24px 25px;
		}

		&__close {
			right: 50px;

			.svg-icon {
				font-size: 22px;
			}
		}

		&__title {
			font-size: 20px;
			max-width: 480px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.form {
		margin-top: 24px;
		max-width: 365px;

		&__cta {
			margin-top: 10px;
		}

		&__price {
			margin-top: 20px;

			img {
				margin-right: 12px;
				width: 54px;
			}

			span {
				font-size: 42px;
			}

			&-product {
				p {
					+ p {
						margin-top: 10px;
					}

					img {
						width: 48px;
						height: 48px;
					}
				}
			}
		}
	}
}
</style>
