import { createRouter, createWebHistory } from "vue-router"
import Home from "@/pages/Home.vue"
import Finish from "@/pages/Finish.vue"
import Faq from "@/pages/Faq.vue"

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/finish",
		name: "Finish",
		component: Finish,
	},
	{
		path: "/faq",
		name: "Faq",
		component: Faq,
	},
	{
		path: "/:pathMatch(.*)",
		redirect: "/",
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes,
})

export default router
