<template>
	<div class="loader" :class="[theme]">
		<BeatLoader :loading="loading" :color="color" :size="size" />
	</div>
</template>

<script>
import BeatLoader from "vue-spinner/src/BeatLoader.vue"

export default {
	name: "Loader",
	components: {
		BeatLoader,
	},
	props: {
		color: {
			type: String,
			default: "#d0c077",
		},
		size: {
			type: String,
			default: "12px",
		},
		loading: {
			type: Boolean,
			default: true,
		},
		theme: {
			type: String,
			default: "inline",
			validator: (theme) => ["inline", "block"].includes(theme),
		},
	},
}
</script>

<style lang="scss" scoped>
.loader {
	&.block {
		display: flex;
		justify-content: center;
		margin: 16px 0;
	}
}

.v-spinner {
	display: flex;
	font-size: 0;
}
</style>
