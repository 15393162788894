const state = () => ({
	activeModal: null,
	modalParams: {},
})

const getters = {}

const mutations = {
	setModal(state, { name, ...params }) {
		state.activeModal = name
		state.modalParams = params ? { ...params } : {}
	},
	hideModal(state) {
		state.activeModal = null
		state.modalParams = {}
	},
}

const actions = {}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
