<template>
	<div class="page">
		<SiteHeader />
		<div class="page__content">
			<slot />
		</div>
	</div>
</template>

<script>
import SiteHeader from "./HeaderSecondary"

export default {
	mounted() {
		document.body.classList.add("secondary")
	},
	components: { SiteHeader },
}
</script>

<style lang="scss" scoped>
.page {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	&__content {
		flex: 1 0 auto;
	}
}
</style>
