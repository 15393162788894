<template>
	<button
		:class="['button', theme, size, isBlock, noPadding, { 'is-loading': showLoader }]"
		v-bind="$attrs"
	>
		<div class="button__icon left" v-if="iconLeft">
			<SvgIcon :name="iconLeftName" :style="iconLeftStyle" />
		</div>
		<div class="button__icon" v-if="theme === 'back'">
			<img src="@/assets/img/bag.png" srcset="@/assets/img/bag@2x.png 2x" alt="bag" />
		</div>
		<div class="button__text">
			<slot />
		</div>
		<div class="button__icon right" v-if="iconRight">
			<SvgIcon :name="iconRightName" :style="iconRightStyle" />
		</div>

		<UiLoader v-if="showLoader" :loading="showLoader" :color="loaderColor" />
	</button>
</template>

<script>
// import { h, resolveDynamicComponent } from "vue"

const loaderDelay = 300

export default {
	props: {
		theme: {
			type: String,
			default: "primary",
			validator: (theme) => ["primary", "accent", "back"].includes(theme),
		},
		size: {
			type: String,
			default: "regular",
			validator: (theme) => ["regular"].includes(theme),
		},
		iconLeft: {
			type: String,
			required: false,
		},
		iconRight: {
			type: String,
			required: false,
		},
		isLoading: {
			type: Boolean,
			required: false,
		},
		loaderColor: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			showLoader: false,
			timer: null,
		}
	},
	computed: {
		iconLeftName() {
			return this.iconLeft.split(":")[0]
		},
		iconLeftStyle() {
			const splitClassname = this.iconLeft.split(":")
			if (splitClassname[1]) {
				return { fontSize: splitClassname[1] + "px" }
			}

			return {}
		},
		iconRightName() {
			return this.iconRight.split(":")[0]
		},
		iconRightStyle() {
			const splitClassname = this.iconRight.split(":")
			if (splitClassname[1]) {
				return { fontSize: splitClassname[1] + "px" }
			}

			return {}
		},
		isBlock() {
			if (this.$attrs.block !== undefined) {
				return "block"
			}
			return ""
		},
		noPadding() {
			if (this.$attrs["no-padding"] !== undefined) {
				return "no-padding"
			}
			return ""
		},
	},
	watch: {
		isLoading(newV, _oldV) {
			if (newV) {
				this.timer = setTimeout(() => {
					this.showLoader = true
				}, loaderDelay)
			} else {
				clearTimeout(this.timer)
				this.showLoader = false
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.button {
	-webkit-appearance: none;
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 9px 22px;
	border: 2px solid transparent;
	box-sizing: border-box;
	border-radius: 30px;
	font-family: $russoFont;
	font-weight: 400;
	font-size: 12px;
	line-height: 1;
	text-align: center;
	cursor: pointer;
	box-shadow: none;
	transition: background 0.25s $ease, color 0.25s $ease;
	&:focus,
	&:active {
		outline: none;
	}

	&__icon {
		position: relative;
		z-index: 3;
		flex: 0 0 auto;
		font-size: 0px;
		.svg-icon {
			font-size: 8px;
		}
		&.left {
			margin-right: 7px;
		}
		&.right {
			margin-left: 7px;
		}
	}

	&__text {
		position: relative;
		z-index: 3;
		display: inline-flex;
		align-items: center;
		::v-deep .checkbox {
			pointer-events: none;
		}
	}

	&.primary {
		background: transparent;
		color: #35271e;
		text-transform: uppercase;
		border-color: $colorPrimary;
		&::after {
			display: inline-block;
			content: " ";
			position: absolute;
			top: 4px;
			right: 4px;
			bottom: 4px;
			left: 4px;
			background: $colorPrimary;
			border-radius: 30px;
			transition: background 0.25s $ease;
		}
		&:hover {
			color: $colorPrimary;
			&::after {
				background: $colorAccent;
			}
		}
		&:active {
			background: $colorAccent;
		}
	}
	&.accent {
		font-family: $baseFont;
		color: $fontColor;
		font-size: 18px;
		border-radius: 3px;
		font-weight: 600;
		background: $colorAccent;
		padding: 4px 9px;
		box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.57);

		&:hover,
		&:active {
			background: rgba(254, 138, 30, 0.4);
		}
		&:active {
			background: rgba(254, 138, 30, 0.3);
		}
	}
	&.back {
		font-family: $baseFont;
		color: $fontColorDark;
		font-size: 10px;
		border-radius: 4px;
		font-weight: 600;
		background: transparent;
		padding: 10px 12px;
		border: 2px solid $fontColorDark;
		.button__icon {
			margin-right: -2px;
			margin-left: -10px;
			margin-top: -2px;
			img {
				max-width: 40%;
			}
			transition: filter 0.25s $ease;
		}
		&:hover,
		&:active {
			background: $fontColorDark;
			color: white;
			.button__icon {
				filter: invert(1);
			}
		}
		&:active {
			background: $fontColorDark;
			color: white;
		}
	}
	&.block {
		display: block;
		width: 100%;
	}

	&.no-padding {
		padding: 0;
	}

	&[disabled] {
		background: #57524e;
		color: #7c7971;
		pointer-events: none;
		transition: none;
	}

	.loader {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	&.is-loading {
		color: transparent !important;
	}
}

@include rmin($sm) {
	.button {
		padding: 14px 30px;
		border-width: 3px;
		font-size: 20px;
		&__icon {
			.svg-icon {
				font-size: 14px;
			}
			&.left {
				margin-right: 10px;
			}
			&.right {
				margin-left: 10px;
			}
		}
		&.accent {
			font-size: 28px;
			border-radius: 5px;
			padding: 6px 10px;
			box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.57);
		}
		&.back {
			font-size: 24px;
			border-radius: 6px;
			padding: 27px 22px;
			border: 3px solid $fontColorDark;
			.button__icon {
				margin-right: 20px;
				margin-top: -4px;
				margin-left: 0;
				img {
					max-width: 100%;
				}
			}
		}
	}
}
</style>
