import { $api } from "@/plugins/Axios"
import endpoints from "./endpoints"
import { buildFormData, mapApiError, createError, mapData } from "./helpers"

export const getUserByIdService = async (id) => {
	try {
		const { data } = await $api.post(endpoints.user.search, buildFormData({ data: { id } }))

		if (!data) throw createError("Not found", 404)

		const userData = mapData(data, true)

		if (userData.error || (data.data && data.data.error))
			throw createError(userData.error || data.data.error, 404)

		return [null, userData]
	} catch (error) {
		return [mapApiError(error), null]
	}
}
