import legacy from "./Legacy"
import { initAxios } from "./Axios"
import veeValidate from "./VeeValidate"
import clickOutside from "./ClickOutside"
import scrollLock from "./ScrollLock"

const initPlugins = (app) => {
	legacy(app)
	initAxios(app)
	veeValidate(app)
	clickOutside(app)
	scrollLock(app)
}

export default initPlugins
