<template>
	<LayoutSecondary>
		<div class="faq">
			<div class="container">
				<div class="faq__content">
					<h1>FAQ</h1>
					<h2>Как найти свой USER ID?</h2>
					<p><strong>1.</strong> Открыть приложение Day R</p>
					<p><strong>2.</strong> Нажать на кнопку “Пауза”</p>
					<img
						src="/static/img/faq/faq-1.jpg"
						srcset="/static/img/faq/faq-1@2x.jpg 2x"
						alt=""
					/>
					<p><strong>3.</strong> В появившемся окне выбрать “Главное меню”</p>
					<img
						src="/static/img/faq/faq-2.jpg"
						srcset="/static/img/faq/faq-2@2x.jpg 2x"
						alt=""
					/>
					<p><strong>4.</strong> В главном меню выбрать раздел “Профиль”</p>
					<img
						src="/static/img/faq/faq-3.jpg"
						srcset="/static/img/faq/faq-3@2x.jpg 2x"
						alt=""
					/>
					<p><strong>5.</strong> В нижней части экрана можно увидеть ваш USER ID</p>
					<img
						src="/static/img/faq/faq-4.jpg"
						srcset="/static/img/faq/faq-4@2x.jpg 2x"
						alt=""
					/>

					<h2>Как совершить покупку на сайте?</h2>
					<p><strong>1.</strong> На главном экране в поле указать свой USER ID</p>
					<p>
						<strong>2.</strong> Проверить никнейм, убедиться что найденный пользователь
						это вы
					</p>
					<p><strong>3.</strong> Перейти на следующий экран и выбрать подходящий товар</p>
					<p>
						<strong>4.</strong> Нажать на товар и указать почту для получения чека в
						появившемся окне и нажать “Купить”
					</p>
					<p><strong>5.</strong> Выбрать способ оплаты</p>
					<p><strong>6.</strong> Произвести процедуру оплаты выбранным способом</p>
					<p><strong>7.</strong> После оплаты на сайте, зайти в игру в раздел “Почта”</p>
					<p><strong>8.</strong> Принять полученный товар из почты</p>

					<div class="faq__cta">
						<UiButton theme="back" @click="$router.push('/')"
							>Вернуться к покупкам</UiButton
						>
					</div>
				</div>
			</div>
		</div>
	</LayoutSecondary>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex"

export default {
	data() {
		return {
			pageLoading: false,
			error: false,
		}
	},

	watch: {},
}
</script>

<style lang="scss" scoped>
.faq {
	margin-top: 24px;
	margin-bottom: 60px;
	&__content {
		max-width: 740px;
		margin-left: auto;
		margin-right: auto;
		line-height: 1.42;
		h1 {
			font-family: $russoFont;
			font-size: 18px;
		}
		h2 {
			margin: 1em 0;
			color: #35271e;
			font-weight: 700;
			font-size: 15px;
		}
		p {
			font-size: 12px;
			line-height: 1.2;
			font-weight: 500;
			strong {
				font-weight: 700;
			}
		}
		img {
			display: block;
			margin: 12px -5px;
			width: calc(100% + 10px);
			font-size: 0;
			box-shadow: 0px 3px 10px rgba(black, 0.42);
		}
	}
	&__cta {
		margin-top: 32px;
		display: flex;
		justify-content: center;
	}
}

@include rmin($sm) {
	.faq {
		margin-top: 42px;
		margin-bottom: 120px;
		&__content {
			h1 {
				font-size: 40px;
			}
			h2 {
				font-size: 34px;
			}
			p {
				font-size: 25px;
				line-height: 1.2;
			}
			img {
				margin: 40px -30px;
				width: calc(100% + 60px);
				box-shadow: 0px 3px 10px rgba(black, 0.42);
			}
		}
		&__cta {
			margin-top: 72px;
		}
	}
}
</style>
