import { $api } from "@/plugins/Axios"
import endpoints from "./endpoints"
import { buildFormData, mapApiError, createError, mapData } from "./helpers"

export const getProductsService = async (request) => {
	try {
		const { data } = await $api.post(endpoints.shop.list, buildFormData(request))

		return [null, mapData(data)]
	} catch (error) {
		return [mapApiError(error), null]
	}
}

export const initPaymentService = async (request) => {
	try {
		const { data } = await $api.post(endpoints.payment.create, buildFormData(request))

		if (!data) throw createError("Not found", 404)
		if (data && data.error) throw createError(data.data.error, 404)

		return [null, mapData(data)]
	} catch (error) {
		return [mapApiError(error), null]
	}
}

export const finishPaymentService = async (request) => {
	try {
		const { data } = await $api.post(endpoints.payment.finish, buildFormData(request))

		if (!data) throw createError("Error", 400)
		if (data && data.result === 0) throw createError("Error", 400)

		return [null, mapData(data)]
	} catch (error) {
		return [mapApiError(error), null]
	}
}
