import { createApp } from "vue"
import "core-js/stable"

import router from "@/config/router"
import store from "@/store"
import initPlugins from "@/plugins"
import * as components from "@/components"
import App from "@/App.vue"

if (process.env.NODE_ENV === "development") {
	window.store = store
}

const app = createApp(App).use(store).use(router)

initPlugins(app)

for (const name in components) {
	app.component(name, components[name])
}

app.mount("#app")
