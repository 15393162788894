<template>
	<div class="page">
		<SiteHeader />
		<div class="page__content">
			<slot />
		</div>
		<SiteFooter />

		<ModalPay />
		<ModalError />
	</div>
</template>

<script>
import SiteHeader from "./Header"
import SiteFooter from "./Footer"

export default {
	mounted() {
		document.body.classList.remove("secondary")
	},
	components: { SiteHeader, SiteFooter },
}
</script>

<style lang="scss" scoped>
.page {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	&__content {
		flex: 1 0 auto;
	}
}
</style>
