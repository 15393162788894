export default {
	user: {
		search: `search/id`,
	},
	shop: {
		list: `products/list`,
	},
	payment: {
		create: "createPayment",
		finish: "https://store.tltgames.net/checkPayment",
	},
}
