<template>
	<header class="header" :class="[userQuery && 'is-compact']">
		<div class="container">
			<div class="header__wrapper">
				<div class="header__logo" @click="handleLogoCLick">
					<img src="/static/img/logo/logo-full.png" alt="logo" />
				</div>
				<div class="header__user">
					<UserCard :user="user" v-if="user" />
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"

export default {
	data() {
		return {}
	},
	computed: {
		userQuery() {
			return this.$route.query.id
		},
		...mapGetters("user", ["user"]),
	},
	methods: {
		handleLogoCLick() {
			if (this.userQuery) {
				this.removeUser()
				this.$router.push("/")
			}
		},
		...mapMutations("user", ["removeUser"]),
	},
}
</script>

<style lang="scss" scoped>
.header {
	&__wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		padding: 16px 0;
		max-width: 680px;
		margin: 0 auto;
	}
	&__logo {
		width: 214px;
		font-size: 0;
		max-width: 100%;
		transition: all 0.1s $ease;
		img {
			width: 100%;
		}
	}
	&__user {
		display: none;
	}
	&.is-compact {
		.header__logo {
			width: 140px;
			// max-width: 140px;
		}
	}
}

@include rmin($sm) {
	.header {
		&__logo {
			width: 340px;
		}
		&.is-compact {
			.header__logo {
				width: 190px;
			}
			.header__user {
				display: block;
			}
			.header__wrapper {
				justify-content: space-between;
			}
		}
	}
}
</style>
