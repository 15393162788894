<template>
	<header class="header">
		<div class="container">
			<div class="header__wrapper">
				<div class="header__logo" @click="handleLogoCLick">
					<img
						src="/static/img/logo/logo-dark.png"
						srcset="/static/img/logo/logo-dark@2x.png 2x"
						alt="logo"
					/>
				</div>
				<div class="header__cta">
					<UiButton theme="back" @click="handleCtaClick"> Вернуться к покупкам </UiButton>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"

export default {
	data() {
		return {}
	},

	methods: {
		handleLogoCLick() {
			this.$router.push("/")
		},
		handleCtaClick() {
			this.$router.push("/")
		},
	},
}
</script>

<style lang="scss" scoped>
.header {
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		padding: 16px 0;
		max-width: 740px;
		margin: 0 auto;
	}
	&__logo {
		width: 155px;
		font-size: 0;
		max-width: 100%;
		img {
			width: 100%;
		}
	}
	&__cta {
		margin-top: 14px;
	}
}

@include rmin($sm) {
	.header {
		&__logo {
			width: 270px;
			margin-right: 20px;
		}
		&__wrapper {
			flex-direction: row;
		}
		&__cta {
			margin-top: 0;
			margin-left: auto;
		}
	}
}
</style>
