<template>
	<div class="product__image" :class="[imgLoaded && 'loaded', opened && 'hidden']">
		<picture>
			<source
				media="(min-width: 680px)"
				:srcset="`
          /static/img/shop/product-${productMainImage}@desktop.png 1x,
          /static/img/shop/product-${productMainImage}@desktop@2x.png 2x
        `"
			/>
			<img
				:src="`/static/img/shop/product-${productMainImage}.png`"
				:srcset="`/static/img/shop/product-${productMainImage}@2x.png 2x`"
				@load="onImgLoad"
			/>
		</picture>
	</div>

	<div class="product__info" :class="[opened && 'visible']">
		<picture>
			<source
				media="(min-width: 680px)"
				:srcset="`/static/img/shop/product-${productId}@desktop-open.png 1x, /static/img/shop/product-${productId}@desktop-open@2x.png 2x`"
			/>
			<img
				:src="`/static/img/shop/product-${productId}-open.png`"
				:srcset="`/static/img/shop/product-${productId}-open@2x.png 2x`"
			/>

			<div v-if="productOptions.length > 0" class="product__options">
				<span
					class="product__options--item"
					v-for="(option, index) in productOptions"
					:key="index"
					>{{ option.item_quantity }}</span
				>
			</div>
		</picture>
	</div>

	<div v-if="productFeature" class="product__feature">
		<picture>
			<source
				media="(min-width: 680px)"
				:srcset="`/static/img/shop/${productFeature}-desktop.png 1x, /static/img/shop/${productFeature}-desktop@2x.png 2x`"
			/>
			<img
				:src="`/static/img/shop/${productFeature}.png`"
				:srcset="`/static/img/shop/${productFeature}@2x.png 2x`"
				alt="опция"
			/>
		</picture>
	</div>

	<div v-if="productShare" class="product__feature" :class="[isHalloween()]">
		<picture>
			<source
				v-if="!isHalloween()"
				media="(min-width: 680px)"
				:srcset="`/static/img/shop/ribbon-red-desktop.png 1x, /static/img/shop/ribbon-red-desktop@2x.png 2x`"
			/>
			<img
				:src="`/static/img/shop/ribbon-red.png`"
				:srcset="`/static/img/shop/ribbon-red@2x.png 2x`"
				alt="опция"
			/>
			<span class="product__feature--text"
				>скидка<span>&nbsp;{{ productShare }}&nbsp;</span></span
			>
		</picture>
	</div>

	<div class="product__content" :class="[isHalloween()]">
		<div class="product__price">{{ price }} <span>₽</span></div>
		<div class="product__trigger" @click.prevent.stop="openInfo">
			<template v-if="!opened">
				<SvgIcon name="question" />
				<span>Что входит в {{ productId == 0 ? "премиум" : "набор" }}?</span>
			</template>
			<template v-else>
				<SvgIcon name="question" />
				<span>Свернуть</span>
			</template>
		</div>
	</div>
</template>

<script>
import { easterProduct, isNewYearProduct } from "../../api/helpers"

export default {
	props: {
		productId: String,
		productShare: String,
		productOptions: [],
		imgLoaded: Boolean,
		price: {
			type: [String, Number],
			required: true,
		},
	},
	computed: {
		productMainImage() {
			return isNewYearProduct(this.productId) ? "ny" : this.productId
		},
		productFeature() {
			let feature = ""

			switch (this.productId) {
				case "11":
					feature = "profitable"
					break
				case "12":
					feature = "share20"
					break
				case "13":
					feature = "share30"
					break
				default:
					feature = ""
			}

			return feature
		},
	},
	emits: ["imgReady"],
	data() {
		return {
			opened: false,
		}
	},
	methods: {
		openInfo() {
			this.opened = !this.opened
		},
		onImgLoad() {
			this.$emit("imgReady")
		},
		isHalloween() {
			if (this.productId == 32) {
				return "halloween"
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.product {
	&__image {
		position: relative;
		z-index: 1;
		font-size: 0;
		transition: opacity 0.1s $ease;

		&:not(.loaded) {
			padding-bottom: 51.4%;

			img {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
			}
		}

		img {
			width: 100%;
		}

		&.hidden {
			height: 0;
			opacity: 0;
		}
	}

	.halloween {
		.product__price {
			margin-top: 5px;
			color: #35271e;
			background: #f0e4ba;
			border-radius: 3px;
			transition: background 0.25s $ease, color 0.25s $ease;

			@media (max-width: 679px) {
				margin-top: 15px;
			}

			&:hover {
				background: white;
				color: $colorAccent;
			}
			// @media (min-width: 680px) {
			// 	margin-top: 15px;
			// }
			// @media (min-width: 720px) {
			// 	margin-top: 15px;
			// }
		}
	}

	&__feature {
		position: absolute;
		z-index: 2;
		top: 70px;
		right: 20px;
		margin-top: 10px;

		&--text {
			font: 10px "Russo One", sans-serif;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			text-align: center;
			color: #ffff9c;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-flow: column nowrap;

			span {
				font-size: 16px;
			}
		}

		@media (min-width: 680px) {
			top: 90px;

			&:not(.halloween) &--text {
				font-size: 28px;
				flex-flow: row nowrap;
				padding-bottom: 10px;
				padding-left: 5px;
				text-transform: uppercase;

				span {
					font-size: inherit;
				}
			}

			&.halloween {
				left: auto;
				right: 25px;
				margin-top: -15px;

				picture {
					display: flex;
					width: 75px;
					height: 75px;
				}
			}

			&.halloween &--text {
				font-size: 13px;
				span {
					font-size: 24px;
				}
			}
		}

		@media (max-width: 679px) {
			&.halloween {
				left: 20px;
				right: auto;
			}
		}
		@media (min-width: 720px) {
			top: 110px;
		}
	}

	&__content {
		position: absolute;
		z-index: 2;
		display: flex;
		flex-direction: column;
		top: 70px;
		left: 20px;
		right: 20px;
		@media (min-width: 340px) {
			top: 80px;
		}
		@media (min-width: 680px) {
			top: 95px;

			&.halloween {
				left: 30%;
				right: 0;
			}
		}

		@media (min-width: 720px) {
			top: 105px;
			&.halloween {
				top: 100px;
			}
		}

		@media (min-width: 760px) {
			top: 110px;
		}

		@media (min-width: 780px) {
			top: 115px;
			&.halloween {
				top: 110px;
			}
		}
	}

	&__trigger {
		padding: 10px 5px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-size: 0;
		transition: color 0.25s $ease;

		svg {
			font-size: 12px;
			margin-right: 4px;
		}

		span {
			font-size: 8px;
			font-weight: 600;
		}

		&:hover {
			color: #e0bd48;
		}
	}

	&__options {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;

		&--item {
			font-weight: bold;
			color: #ffeab0;
			font-size: 14px;
			line-height: 1.2;
			text-shadow: 0 4px 0 rgba(0, 0, 0, 0.004);
			position: absolute;
			right: 6.5%;

			&:nth-last-child(1) {
				bottom: 5.5%;
			}

			&:nth-last-child(2) {
				bottom: 24%;
			}

			&:nth-last-child(3) {
				bottom: 43%;
			}
		}
	}

	&__info {
		font-size: 0;
		opacity: 0;
		pointer-events: none;
		height: 0;
		// transition: opacity 0.1s $ease;

		img {
			width: 100%;
		}

		&.visible {
			height: auto;
			opacity: 1;
		}
	}

	&__price {
		margin: auto auto 0;
		width: 40%;
		padding: 3px 10px;
		background: $colorAccent;
		font-family: $russoFont;
		font-size: 20px;
		transition: background 0.25s $ease, color 0.25s $ease;

		@media (max-width: 679px) {
			margin: 10px auto 0;
			border-radius: 4px;
		}

		span {
			font-family: $baseFont;
			font-weight: 600;
		}

		&:hover {
			background: white;
			color: $colorAccent;
		}
	}

	&.light-buttons {
		.product__price {
			color: #35271e;
			background: #f0e4ba;
			&:hover {
				background: white;
				color: $colorAccent;
			}
		}
	}

	// &:hover {
	// }
}

@include rmin($sm) {
	.product {
		&__image {
			&:not(.loaded) {
				padding-bottom: 29.6%;
			}
		}

		&__content {
			position: absolute;
			z-index: 2;
			left: 50%;
			right: 20px;
		}

		&__feature {
			margin-top: 15px;
			left: 20px;
			right: auto;
		}

		&__trigger {
			padding: 10px;

			svg {
				font-size: 16px;
				margin-right: 6px;
			}

			span {
				font-size: 10px;
				font-weight: 600;
			}
		}

		&__options {
			&--item {
				font-size: 24px;
				right: 7.5%;

				&:nth-last-child(1) {
					bottom: 15.5%;
				}

				&:nth-last-child(2) {
					bottom: 33.5%;
				}

				&:nth-last-child(3) {
					bottom: 51%;
				}
			}
		}

		&__price {
			font-size: 23px;
			border-radius: 4px;
			padding: 6px 10px;
		}
	}
}

@include r($sm) {
	.product {
		&.right-buttons {
			.product__content {
				left: 36%;
				right: 9%;
				.product__price {
					min-width: 112px;
				}
			}
		}
	}
}
</style>
