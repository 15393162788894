<template>
	<div>
		<router-view />
	</div>
</template>

<script>
export default {
	name: "app",
	async created() {
		await this.$store.dispatch("init")
	},
}
</script>

<style lang="scss">
@import "@/assets/styles/index.scss";
</style>
