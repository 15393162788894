<template>
	<div class="modal" :class="[activeModal === 'error' && 'is-active']">
		<div class="modal__wrapper" @click="hideModal">
			<div class="modal__body" @click.stop v-scroll-lock="activeModal === 'error'">
				<div class="modal__close" @click="hideModal">
					<SvgIcon name="close" />
				</div>

				<div class="modal__title">
					Ошибка сервера, напишите на почту
					<a href="mailto:support@tltgames.net">support@tltgames.net</a>
				</div>

				<div class="modal__cta">
					<UiButton theme="accent" type="button" @click="hideModal"> Понятно </UiButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex"

export default {
	data() {
		return {}
	},
	computed: {
		...mapState("ui", ["activeModal", "modalParams"]),
	},
	methods: {
		...mapMutations("ui", ["hideModal"]),
		...mapActions("shop", ["initPayment"]),
	},
}
</script>

<style lang="scss" scoped>
.modal {
	position: fixed;
	z-index: 99;
	display: flex;
	flex-direction: column;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	&__wrapper {
		flex: 1 0 auto;
		height: 100%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0);
		backface-visibility: hidden;
		will-change: background;
		transition: background 0.25s $ease;
	}
	&__body {
		position: relative;
		background-image: url("@/assets/img/searchBox@1x.png");
		background-image: -webkit-image-set(
			url("@/assets/img/searchBox@1x.png") 1x,
			url("@/assets/img/searchBox@2x.png") 2x
		);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center top;
		width: 100%;
		max-width: 320px;
		min-height: 250px;
		padding: 40px 40px 24px;
		transform: translate3d(0%, 40px, 0);
		opacity: 0;
		overflow-y: auto;
		will-change: transform;
		backface-visibility: hidden;
		transition: transform 0.25s $ease, opacity 0.25s $ease;
	}
	&__close {
		position: absolute;
		z-index: 2;
		top: 5px;
		right: 25px;
		font-size: 0;
		padding: 10px;
		cursor: pointer;
		color: $colorPrimary;
		transition: color 0.25s $ease;
		.svg-icon {
			font-size: 15px;
		}
		&:hover {
			color: $colorRed;
		}
	}
	&__title {
		text-align: center;
		font-family: $russoFont;
		font-size: 13px;
		line-height: 1.25;
		a {
			color: $colorPrimary;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
	&__cta {
		display: flex;
		justify-content: center;
		margin-top: 24px;
	}

	&.is-active {
		pointer-events: all;
		.modal__wrapper {
			background: rgba(#333333, 0.67);
		}
		.modal__body {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}
}

@include rmin($sm) {
	.modal {
		&__body {
			max-width: 768px;
			margin-left: 0px;
			margin-right: -15px;
			background-image: url("@/assets/img/searchBoxDesktop.png");
			background-image: -webkit-image-set(
				url("@/assets/img/searchBoxDesktop.png") 1x,
				url("@/assets/img/searchBoxDesktop@2x.png") 2x
			);
			min-height: 390px;
			padding: 60px 40px 24px 25px;
		}
		&__close {
			right: 50px;
			.svg-icon {
				font-size: 22px;
			}
		}
		&__title {
			font-size: 20px;
			max-width: 480px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
</style>
