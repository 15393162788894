<template>
	<LayoutPrimary>
		<div class="finish">
			<div class="container">
				<template v-if="!error">
					<h1 class="finish__title">Спасибо за покупку</h1>
					<div class="finish__cta">
						<UiButton iconRight="arrow-right" @click="$router.push('/')">ОК</UiButton>
					</div>
				</template>

				<template v-else>
					<h1 class="finish__title">Ошибка</h1>
					<p class="finish__desc">
						Напишите на почту
						<a href="mailto:support@tltgames.net">support@tltgames.net</a>
					</p>
					<div class="finish__cta">
						<UiButton iconRight="arrow-right" @click="$router.push('/')">ОК</UiButton>
					</div>
				</template>

				<UiLoader v-if="pageLoading" theme="block" />
			</div>
		</div>
	</LayoutPrimary>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex"

export default {
	data() {
		return {
			pageLoading: false,
			error: false,
		}
	},
	async mounted() {
		if (this.queryID) {
			this.pageLoading = true
			const data = await this.finishPayment({ data: { id: this.queryID } }).catch((err) => {
				this.error = true
			})

			this.pageLoading = false

			if (data && ["pending", "canceled"].includes(data.status)) {
				this.$router.push(`/?id=${data.user_id}`)
			}
		} else {
			this.error = true
		}
	},
	computed: {
		queryID() {
			return this.$route.query.id
		},
	},
	methods: {
		...mapActions("shop", ["finishPayment"]),
	},
	watch: {},
}
</script>

<style lang="scss" scoped>
.finish {
	margin-top: 42px;

	&__box {
		margin-left: -15px;
		margin-right: -15px;
		background-image: url("@/assets/img/searchBox@1x.png");
		background-image: -webkit-image-set(
			url("@/assets/img/searchBox@1x.png") 1x,
			url("@/assets/img/searchBox@2x.png") 2x
		);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center top;
		min-height: 250px;
		padding: 40px 40px 24px;
		&.has-user {
			padding-top: 16px;
		}
	}
	&__title {
		font-size: 17px;
		font-family: $russoFont;
		text-transform: uppercase;
		text-align: center;
		pointer-events: none;
		text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.57);
	}
	&__desc {
		text-align: center;
	}
	&__cta {
		display: flex;
		justify-content: center;
		margin-top: 24px;
	}
}

@include rmin($sm) {
	.finish {
		margin-top: 64px;

		&__title {
			font-size: 27px;
		}
	}
}
</style>
