import { getUserByIdService } from "@/api/user"

const state = () => ({
	user: null,
})

const getters = {
	user: (state) => {
		return state.user
	},
}

const mutations = {
	setUser(state, user) {
		state.user = user
	},
	removeUser(state) {
		state.user = null
	},
}

const actions = {
	async findUserById({ commit, dispatch }, id) {
		const [err, result] = await getUserByIdService(id)

		if (err) throw err
		commit("setUser", result)

		return result
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
