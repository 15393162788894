<template>
	<footer class="footer">
		<div class="container">
			<div class="footer__wrapper">
				<a href="https://tltgames.ru/" target="_blank" class="footer__logo">
					<SvgIcon name="logo" />
				</a>
				<div class="footer__socials">
					<a href="https://t.me/DayR_game" target="_blank" class="footer__social-link">
						<SvgIcon name="social-tg" />
					</a>
					<a
						href="https://discord.gg/JtYUxrHVCY"
						target="_blank"
						class="footer__social-link"
					>
						<SvgIcon name="social-discord" />
					</a>
					<a href="https://vk.com/dayr.game" target="_blank" class="footer__social-link">
						<SvgIcon name="social-vk" />
					</a>
				</div>
				<div class="footer__links">
					<a
						href="https://store.tltgames.net/public-offer.pdf"
						target="_blank"
						class="footer__link"
						>Публичная оферта</a
					>
					<a
						href="https://store.tltgames.net/privacy-policy.pdf"
						target="_blank"
						class="footer__link"
					>
						Политика конфиденциальности
					</a>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	data() {
		return {}
	},
}
</script>

<style lang="scss" scoped>
.footer {
	position: relative;
	&__wrapper {
		padding: 20px 0 20px;
		text-align: center;
	}
	&__logo {
		font-size: 0;
		color: $colorPrimary;
		transition: color 0.25s $ease;
		svg {
			font-size: 32px;
		}
		&:hover {
			color: $fontColor;
		}
	}
	&__socials {
		margin-top: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
	}
	&__social-link {
		font-size: 0;
		margin-right: 4px;
		color: $colorPrimary;
		transition: color 0.25s $ease;
		&:last-child {
			margin-right: 0;
		}
		svg {
			font-size: 22px;
		}
		&:hover {
			color: $fontColor;
		}
	}
	&__links {
		margin-top: 8px;
		display: flex;
		flex-direction: column;
	}
	&__link {
		margin-bottom: 4px;
		font-size: 11px;
		color: $colorPrimary;
		transition: color 0.25s $ease;
		&:last-child {
			margin-bottom: 0;
		}
		&:hover {
			color: $fontColor;
		}
	}
}
@include rmin($sm) {
	.footer {
		&__wrapper {
			padding: 40px 0 48px;
		}
		&__logo {
			svg {
				font-size: 52px;
			}
		}
		&__socials {
			margin-top: 14px;
		}
		&__social-link {
			margin-right: 8px;
			svg {
				font-size: 34px;
			}
		}
		&__links {
			margin-top: 12px;
		}
		&__link {
			font-size: 16px;
			margin-bottom: 5px;
		}
	}
}
</style>
