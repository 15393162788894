import { createStore } from "vuex"
import ui from "./ui"
import user from "./user"
import shop from "./shop"

export default createStore({
	actions: {
		async init({ dispatch, commit }) {
			// dispatch("shop/getProducts")
		},
	},
	modules: {
		ui,
		user,
		shop,
	},
})
