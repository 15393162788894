<template>
	<LayoutPrimary>
		<div class="search">
			<div class="container">
				<UiLoader v-if="pageLoading" theme="block" />

				<div class="loader" v-if="!shopReady">
					<SvgIcon name="loader" />
				</div>
				<!-- <div class="row"> -->
				<div
					class="search__box"
					:class="[user && 'has-user']"
					v-if="!pageLoading && !showShop"
				>
					<h1 class="search__title">укажите свой аккаунт</h1>
					<form class="search__form" @submit.prevent="handleSubmit">
						<UiInput
							:value="search"
							placeholder="Введите свой USER ID"
							@onChange="handleSearchChange"
							:error="error"
						/>
						<div class="search__error" v-if="error">
							Аккаунт не найден. <br />Проверьте ID и повторите поиск
						</div>
						<div class="search__quide" :class="[error && 'with-error']">
							Гайд:
							<router-link to="/faq">Как найти свой USER ID?</router-link>
						</div>
						<div class="search__cta">
							<UiButton type="submit" iconRight="arrow-right">искать </UiButton>
						</div>
					</form>

					<div class="search__user" v-if="user">
						<div class="search__user-title">это ваш аккаунт?</div>
						<UserCard :user="user" :showArrow="true" @click="navigateToShop" />
					</div>

					<h2 class="search__allert">
						внимание!<br />покупки доступны начиная с версии игры v.723
					</h2>
				</div>
				<!-- </div> -->

				<div class="search__shop shop" v-if="!pageLoading && showShop">
					<div class="shop__user">
						<UserCard :user="user" v-if="user" />
					</div>

					<div class="shop__grid">
						<ShopProduct
							v-for="product in products"
							:key="product.id"
							:product="product"
							@imgReady="imgReady"
						/>
					</div>
				</div>
			</div>
		</div>
	</LayoutPrimary>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex"

export default {
	data() {
		return {
			pageLoading: false,
			search: "",
			loading: false,
			error: false,
			showShop: false,
			readyImages: [],
		}
	},
	async mounted() {
		if (this.userQuery) {
			this.pageLoading = true
			const user = await this.findUserById(this.userQuery).catch((err) => {
				this.error = true
			})

			this.showShop = user ? true : false
			this.pageLoading = false

			if (user) {
				await this.getProducts({ data: { user_id: user.id } })
			}
		}
	},
	computed: {
		shopReady() {
			if (!this.showShop) return true
			return !this.pageLoading && this.readyImages.length >= 6
		},
		userQuery() {
			return this.$route.query.id
		},
		...mapGetters("user", ["user"]),
		...mapGetters("shop", ["products"]),
	},
	methods: {
		handleSearchChange(v) {
			this.search = v
			if (this.error) {
				this.error = false
			}
		},
		imgReady(id) {
			this.readyImages.push(id)
		},
		async handleSubmit() {
			if (this.loading) return

			const searchField = this.search.trim()
			const isSearchingId = Number.isFinite(Number(searchField))

			if (this.search.trim() <= 1 || !isSearchingId) {
				this.error = true
				return
			}

			this.loading = true
			this.error = false

			const user = await this.findUserById(searchField).catch((err) => {
				this.error = true
			})

			this.loading = false
		},
		navigateToShop() {
			this.search = ""
			this.$router.push(`/?id=${this.user.id}`)
		},
		...mapMutations("user", ["removeUser"]),
		...mapActions("user", ["findUserById"]),
		...mapActions("shop", ["getProducts"]),
	},
	watch: {
		userQuery(newVal, oldVal) {
			if (newVal !== oldVal) {
				this.showShop = !!newVal
			}
		},
		showShop(newVal) {
			if (newVal) {
				this.getProducts({ data: { user_id: this.user.id } })
			}
		},
		error(newVal, oldVal) {
			if (newVal === true && newVal !== oldVal) {
				this.removeUser()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.search {
	margin-top: 24px;

	&__box {
		margin-left: -15px;
		margin-right: -15px;
		background-image: url("@/assets/img/searchBox@1x.png");
		background-image: -webkit-image-set(
			url("@/assets/img/searchBox@1x.png") 1x,
			url("@/assets/img/searchBox@2x.png") 2x
		);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center top;
		min-height: 250px;
		padding: 40px 40px 24px;
		&.has-user {
			padding-top: 16px;
		}
	}
	&__allert {
		margin-top: 30px;
		font-size: 17px;
		font-family: $russoFont;
		text-transform: uppercase;
		text-align: center;
		pointer-events: none;
		color: #d9a331;
		text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.57);
	}
	&__title {
		font-size: 17px;
		font-family: $russoFont;
		text-transform: uppercase;
		text-align: center;
		pointer-events: none;
		text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.57);
	}
	&__form {
		max-width: 225px;
		margin-left: auto;
		margin-right: auto;
	}
	&__cta {
		margin-top: 12px;
		text-align: center;
	}
	&__error {
		margin: 6px 0;
		font-size: 11px;
		line-height: 1.235;
		text-align: center;
		color: $colorRed;
	}
	&__quide {
		margin-top: 8px;
		font-size: 11px;
		display: flex;
		justify-content: center;
		a {
			text-decoration: underline;
			margin-left: 5px;
			&:hover {
				text-decoration: none;
			}
		}
		&.with-error {
			text-align: center;
			margin-left: 0;
		}
	}
	&__user {
		margin-top: 14px;
		max-width: 225px;
		margin-left: auto;
		margin-right: auto;
	}
	&__user-title {
		margin-bottom: 10px;
		font-size: 12px;
		font-family: $russoFont;
		text-transform: uppercase;
		text-align: center;
		text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.57);
	}

	// &__shop {
	// }
}

@include rmin($md) {
	.search {
		&__allert {
			font-size: 21px;
			font-family: $russoFont;
			text-transform: uppercase;
			text-align: center;
			pointer-events: none;
			color: #d9a331;
			text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.57);
			margin-top: 50px;
			margin-bottom: 50px;
		}
	}
}

@include rmin($sm) {
	.search {
		margin-top: 42px;
		&__box {
			margin-left: 0px;
			margin-right: -15px;
			background-image: url("@/assets/img/searchBoxDesktop.png");
			background-image: -webkit-image-set(
				url("@/assets/img/searchBoxDesktop.png") 1x,
				url("@/assets/img/searchBoxDesktop@2x.png") 2x
			);
			min-height: 390px;
			padding: 40px 40px 24px 25px;
			&.has-user {
				padding-top: 16px;
			}
		}
		&__title {
			font-size: 27px;
		}
		&__form {
			max-width: 365px;
		}
		&__cta {
			margin-top: 22px;
		}

		&__error {
			margin: 12px 0 6px;
			font-size: 15px;
		}
		&__quide {
			margin-top: 14px;
			font-size: 14px;
		}

		&__user {
			margin-top: 24px;
			max-width: 365px;
		}
		&__user-title {
			margin-bottom: 16px;
			font-size: 19px;
		}
	}
}

.shop {
	&__user {
		max-width: 225px;
		margin-left: auto;
		margin-right: auto;
	}
	&__grid {
		display: flex;
		flex-wrap: wrap;
		margin: 10px 0px -20px;
	}
}

@include rmin($sm) {
	.shop {
		&__user {
			display: none;
			max-width: 365px;
		}
		&__grid {
			max-width: 720px;
			margin: 40px auto 0px;
		}
	}
}

.loader {
	position: fixed;
	z-index: 99;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0;
	background: rgba(black, 0.5);
	backdrop-filter: blur(10px);
	color: $colorPrimary;
	svg {
		font-size: 130px;
	}
	@include rmin($sm) {
		svg {
			font-size: 180px;
		}
	}
}
</style>
