import { getProductsService, initPaymentService, finishPaymentService } from "@/api/shop"

const state = () => ({
	products: [],
})

const getters = {
	products: (state) => {
		return state.products
	},
}

const mutations = {
	setProducts(state, products) {
		state.products = products
	},
}

const actions = {
	async getProducts({ commit, dispatch }, request) {
		const [err, result] = await getProductsService(request)

		if (err) throw err
		commit("setProducts", result)

		return result
	},
	async initPayment({ commit, dispatch }, request) {
		const [err, result] = await initPaymentService(request)

		if (err) throw err

		return result
	},
	async finishPayment({ commit, dispatch }, request) {
		const [err, result] = await finishPaymentService(request)

		if (err) throw err

		return result
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
