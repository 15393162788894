<template>
	<div class="user" :class="[showArrow && 'is-clickable']" v-bind="$attrs">
		<div class="user__avatar">
			<SvgIcon name="user" />
		</div>
		<div class="user__content">
			<div class="user__login">{{ user.name }}</div>
			<div class="user__id">ID {{ user.id }}</div>
		</div>
		<div class="user__icon" v-if="showArrow">
			<SvgIcon name="arrow-right" />
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
	data() {
		return {}
	},
	props: {
		showArrow: Boolean,
		user: {
			type: Object,
			required: true,
		},
	},
	computed: {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.user {
	display: flex;
	align-items: center;
	padding: 3px 10px;
	border-radius: 4px;
	border: 1px solid $colorPrimary;
	background-color: $colorAccent;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.57);
	transition: border 0.25s $ease;
	&__avatar {
		flex: 0 0 auto;
		font-size: 0;
		svg {
			font-size: 34px;
		}
	}
	&__content {
		flex: 1 1 auto;
		padding: 0 12px;
	}
	&__login {
		font-weight: 600;
		font-size: 11px;
	}
	&__id {
		margin-top: 4px;
		font-size: 11px;
	}
	&__icon {
		flex: 0 0 auto;
		font-size: 0;
		color: $colorPrimary;
		transition: color 0.25s $ease;
		svg {
			font-size: 15px;
		}
	}
	&.is-clickable {
		cursor: pointer;
		&:hover {
			border-color: white;
			.user {
				&__icon {
					color: white;
				}
			}
		}
	}
}

@include rmin($sm) {
	.user {
		padding: 6px 20px;
		&__avatar {
			svg {
				font-size: 52px;
			}
		}
		&__content {
			padding: 0 22px;
		}
		&__login {
			font-size: 16px;
		}
		&__id {
			margin-top: 6px;
			font-size: 16px;
		}
		&__icon {
			svg {
				font-size: 22px;
			}
		}
	}
}
</style>
